<template>
	<div>
		<t-button
			@click="rescan"
			class="m-auto"
			v-if="!isAuthorized && !isInitilized && osAppMode != 'ios'"
		>
			Retry
		</t-button>
		<div
			v-if="!isInitilized"
			class="bg-center bg-contain bg-no-repeat opacity-25 h-72"
			:style="`background-image: url(${image})`"
		/>
		<qrcode-stream
			v-if="isAuthorized && osAppMode != 'ios'"
			@init="onInit"
			@decode="onDecode"
		/>
		<div v-if="table.number" class="text-center text-lg text-grey-600">
			TABLE N° {{ table.number }} <br />
			{{ table.comment }} <br />
			SERVER {{ table.user.name }}
		</div>
	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { QrcodeStream } from 'vue-qrcode-reader';
import { ApiGetTable } from '@/tools/apiService';
import { formatDateForForm } from '@/tools/helpers';
import { ApiAddMessage, ApiAddAnonymeMessage } from '@/tools/apiService';
/* global QRScanner */

export default {
	data: () => ({
		isAuthorized: false,
		isInitilized: false,
		isDecoding: false,
		image: '',
		table: {}
	}),
	mounted: function() {
		this.image = require('@/assets/images/qr_icon.svg.png');
		if (this.$route.query.qrcode && this.$route.query.qrcode != '') {
			this.onDecode(this.$route.query.qrcode);
		} else if (this.$root.env.VUE_APP_MODE == 'android') {
			QRScanner.prepare((error, status) => {
				console.log('error', error);
				if (status.authorized) {
					this.$root.isLoading = true;
					this.isAuthorized = true;
				} else {
					this.$notify.error({
						title: 'You have refused to give access to camera',
						position: 'top center'
					});
				}
			});
		} else if (this.$root.env.VUE_APP_MODE == 'ios') {
			QRScanner.prepare(() => {
				QRScanner.show(() => {
					document.body.style.opacity = 0.5;
				});

				QRScanner.scan((error, text) => {
					document.body.style.opacity = 0.5;
					if (error) console.log('error', error);
					else this.onDecode(text);
				});
			});
		} else {
			this.$root.isLoading = true;
			this.isAuthorized = true;
		}
	},
	beforeDestroy: function() {
		if (this.$root.env.VUE_APP_MODE == 'ios') {
			try {
				document.body.style.opacity = 1;
				QRScanner.destroy(status => {
					console.log('status', status);
				});
			} catch (error) {
				console.log('error', JSON.stringify(error));
			}
		}
	},
	computed: {
		...mapGetters({
			user: 'auth/user'
		}),
		osAppMode: function() {
			return this.$root.env.VUE_APP_MODE;
		}
	},
	methods: {
		...mapActions({
			saveAdditionalInfo: 'cart/saveAdditionalInfo'
		}),
		rescan: function() {
			this.isAuthorized = true;
			this.isInitilized = true;
			this.table = {};
		},
		onDecode: function(decodedString) {
			if (this.isDecoding) {
				console.log('is decoding ...');
				return;
			}

			console.log('decoded string', decodedString);
			this.isDecoding = true;

			let qrCode = decodedString;

			if (decodedString.startsWith('http')) {
				const url = new URL(decodedString);
				qrCode = url.searchParams.get('qrcode');
			}

			ApiGetTable(qrCode)
				.then(response => {
					this.isDecoding = false;
					this.table = response.data.table;
					this.saveAdditionalInfo({
						planifiedFor: formatDateForForm(new Date()),
						comment: `---`,
						tableId: this.table.id,
						address: 'N/A',
						city: 'N/A',
						code_postal: 'N/A',
						country: 'N/A',
						region: 'N/A',
						lat: 0,
						lng: 0
					}).then(() => {
						this.isAuthorized = false;
						this.isInitilized = false;
						if (
							this.$route &&
							this.$route.query &&
							this.$route.query.callserver == true
						) {
							this.sendHi(this.table);
						} else {
							setTimeout(() => {
								this.$router.push({
									name: 'restaurant-show',
									params: {
										restaurantId: this.table.restaurantId
									}
								});
							}, 500);
						}
					});
				})
				.catch(error => {
					console.log('error on decoding and calling', error);
					this.isDecoding = false;
				});
		},
		sendHi: function(table) {
			let promise = null;
			let message = {
				subject: `boite message pour la table ${table.number}`,
				message: '👋',
				recevierId: table.userId
			};
			if (this.user) promise = ApiAddMessage(message);
			else promise = ApiAddAnonymeMessage(message);
			promise.then(() => {
				this.$notify.success({
					title: 'Your demand was successfuly sent',
					position: 'top center'
				});
			});
		},
		async onInit(promise) {
			try {
				await promise;
				this.isInitilized = true;
				// successfully initialized
			} catch (error) {
				let message = '';
				if (error.name === 'NotAllowedError')
					message = 'user denied camera access permisson';
				else if (error.name === 'NotFoundError')
					message = 'no suitable camera device installed';
				else if (error.name === 'NotSupportedError')
					message = 'page is not served over HTTPS (or localhost)';
				else if (error.name === 'NotReadableError')
					message = 'maybe camera is already in use';
				else if (error.name === 'OverconstrainedError')
					message =
						'did you requested the front camera although there is none?';
				else if (error.name === 'StreamApiNotSupportedError')
					message = 'browser seems to be lacking features';
				else message = "we Couldn't initilize the scanner";

				this.$notify.error({
					title: message,
					position: 'top center'
				});
			} finally {
				// hide loading indicator
				this.$root.isLoading = false;
			}
		}
	},
	components: {
		QrcodeStream
	}
};
</script>

<style></style>
